import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import SuccessPayment from '../../assets/images/successPayment.svg';
import { useDispatch } from 'react-redux';
import { RESET_CART } from '../../store/slices/cartSlice';
import Loader from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';

export function PaymentSuccess() {
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const dispatch = useDispatch();
  

  const isSuccess = localStorage.getItem('isSuccess');
  const orderId = localStorage.getItem('orderId');
  
  const {i18n}=useTranslation()
    const {  PaymentSuccessful,
      proccessOrder,
      proccessSuccessFully,
      viewOrderPayment,
      goToHomePayment}=i18n.getDataByLanguage(i18n.language);
      
  useEffect(() => {
    const token = getToken();
    if (!orderId) return;


    const handleSuccessOrder = async () => {
      setIsLoadingFetch(true);
      try {

        const res = await api.put(
          `orders/${orderId}/status?status=COMPLETED`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        localStorage.setItem('isSuccess', true);
        localStorage.removeItem('orderId');
        dispatch(RESET_CART ());
      } catch (error) {
        if (error.response?.status === 404) {
          //showInfo('Order already processed or not found.');
        } else {
          // Handle other errors if necessary
        }
      } finally {
        setIsLoadingFetch(false);
      }
    };

    if (!isSuccess) {
      handleSuccessOrder();
    }

    return () => {
      localStorage.removeItem('isSuccess');
    };
  }, [isSuccess, dispatch, orderId]);

  return (
   <> {
      isLoadingFetch?
      <Loader />
      :
      <div className=" py-16 flex flex-col items-center justify-center bg-green-50 text-base-accent-1">
      <div className="w-11/12 m-auto h-[400px]  bg-white items-center md:w-1/2 flex flex-col md:flex-row justify-center  p-5 md:p-10 md:border md:border-1 rounded-[1rem]">
        <div className="w-full   flex justify-center items-center flex-col">
        <div className='bg-green-300/50 flex items-center justify-center rounded-[50%] w-20 h-20'>
        <svg class="h-12 w-12  text-base-accent-1 dark:text-green-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" ariaHidden="true" dataSlot="icon">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
        </svg> 
        </div>
          <h6 className="text-xl font-semibold text-base-accent-1 my-4">{PaymentSuccessful}</h6>
          <p className="text-gray-500 mb-6">
            {isLoadingFetch
              ? {proccessOrder}
              : proccessSuccessFully}
          </p>
          <div className="flex flex-col md:flex-row gap-4">
            <Link
              to="/user/orders"
              className="px-5 w-full md:w-fit text-center py-3 bg-base-accent-1 text-white rounded-[30px] hover:bg-green-700 transition duration-300"
              disabled={isLoadingFetch} // Disable the button while loading
            >
              {viewOrderPayment}
            </Link>
            <Link
              to="/"
              className="px-5 py-3 w-full md:w-fit  text-center bg-base-accent-1/30 text-base-accent-1 rounded-[30px] hover:bg-green-200 transition duration-300"
              disabled={isLoadingFetch} // Disable the button while loading
            >
              {goToHomePayment}
            </Link>
          </div>
        </div>
        
      </div>
    </div>

    }
        </>
  );
}
