import React, { useState, useTransition } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/narart.png';
import ListItems from '../../assets/icons/ListItems';
import Home from '../../assets/icons/Home';
import Pencil from '../../assets/icons/Pencil';
import { useSelector } from 'react-redux';
import List from '../../assets/icons/List';
import Exit from '../../assets/icons/Exit';
import { useDispatch } from 'react-redux';
import {logout} from '../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';

export default function UserVerticalMenu() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
    const dispatch=useDispatch();
    const navigate=useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();
  const {
    UserHomePage,
    UserAccount,
    UserOrders
  } = i18n.getDataByLanguage(i18n.language);


  // active class for links
  const getActiveClass = (path) =>
    location.pathname === path
      ? 'bg-[rgba(16,84,77,0.5)] text-white font-bold'
      : 'hover:bg-[rgba(16,84,77,0.2)]';

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

   const exitHandler=()=>{
      dispatch(logout());
      navigate('/')
    }
  return (
    <div className="relative">
      {/* Overlay background when menu is open, but not on the menu itself */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={closeMenu}
        ></div>
      )}

      <div className={`sticky h-full bg-white  md:border-r md:w-[300px] z-20`}>
        {/* Logo and mobile menu toggle */}
        <div className={`flex ${isOpen && 'hidden'} justify-between items-center p-4`}>
          <button
            className="md:hidden text-lg"
            onClick={toggleMenu}
          >
            {/* Hamburger Icon */}
            <span className="fixed"><List /></span>
          </button>
        </div>

        {/* Sidebar links */}
        <div 
          className={`${
            isOpen ? 'translate-x-0   opacity-100' : '-translate-x-full md:translate-x-0 md:opacity-100 opacity-0'
          } md:block divide-y text-base-accent-1 pt-5  flex flex-col absolute md:static bg-white w-64 md:w-auto z-30 h-[100vh] transition-all duration-500 ease-in-out`}
        >
          {/* Close button for the mobile menu */}
          <div className="flex justify-end px-4 py-2">
            <img src={logo} className="w-[150px] m-auto md:w-[200px]" alt="masooreh logo" />
          </div>

          {/* Menu Items */}
          <div >
            <Link
              to="/"
              className={`flex text-[14px] md:text-[16px] px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/')}`}
              onClick={closeMenu} // Close menu when clicking on an item
            >
              <span>
                <Home />
              </span>
              {UserHomePage}
            </Link>
            <Link
            to="/user/dashboard"
            className={`flex text-[14px] md:text-[16px]  px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/user/dashboard')}`}
            onClick={closeMenu} // Close menu when clicking on an item
          >
            <span>
              <Pencil />
            </span>
            {UserAccount}
            
          </Link>
            <Link
            to="/user/orders"
            className={`flex text-[14px] md:text-[16px]  px-1 md:px-5 py-4 gap-2 items-center duration-300 ${getActiveClass('/user/orders')}`}
            onClick={closeMenu} // Close menu when clicking on an item
          >
            <span>
              <ListItems />
            </span>
          {UserOrders}
          </Link>
          <p
              className={`flex text-[14px] md:text-[16px] px-1 py-4 md:px-5 gap-2 items-center duration-300 ${getActiveClass('/admin/contact-messages')}`}
              onClick={exitHandler} 
            >
              <span>
                <Exit />
              </span>
              Exit
            </p>
            
           
            
          </div>
        </div>
      </div>
    </div>
    //
   
  );
}
