import React, { useEffect, useState } from 'react'
import usFlag from "../../../assets/images/united-states-of-america.png";
import GermanFlag from "../../../assets/images/germany.png";
import { useTranslation } from "react-i18next";

function LanguageSelection() {
    const [languageOpen, setLanguageOpen] = useState(false); // State for language dropdown
    const { i18n } = useTranslation();


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("language", lng); // Save language to Local Storage
        setLanguageOpen(false); // Close language dropdown
      };
    
      const currentLanguage = i18n.language; // Current language
       // Load language from Local Storage
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "de"; // Default English
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);
    
  return (
    
    <div className="relative ">
              <button
                className="block  py-2 text-left "
                onClick={() => setLanguageOpen((prev) => !prev)}
              >
                <img
                  src={currentLanguage === "de" ? GermanFlag : usFlag}
                  alt="Language Flag"
                  className="inline w-7 h-7 mr-2  rounded-full"
                />
              </button>
              {languageOpen && (
                <ul className="absolute text-[14px] text-base-accent-1 bg-white top-10 shadow-lg z-10 rounded-lg mt-2">
                  <li
                    className={`px-4 py-2 ${
                      currentLanguage === "de"
                        ? "bg-gray-200 cursor-not-allowed"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() =>
                      currentLanguage !== "de" && changeLanguage("de")
                    }
                  >
                   
                    German
                  </li>
                  <li
                    className={`px-4 py-2 ${
                      currentLanguage === "en"
                        ? "bg-gray-200 cursor-not-allowed"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() =>
                      currentLanguage !== "en" && changeLanguage("en")
                    }
                  >
                    
                    English
                  </li>
                </ul>
              )}
            </div>
  )
}

export default LanguageSelection