import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowDown from "../../../assets/icons/ArrowDown";
import { useTranslation } from "react-i18next";

function Submenu({ categories,setMenuOpen   }) {
  const { t, i18n } = useTranslation();
  const { ShopMenu } = i18n.getDataByLanguage(i18n.language);

  // Split categories into columns for better display
  const splitCategories = (categories || []).reduce(
    (result, category, index) => {
      const columnIndex = Math.floor(index / 2); // Determine the column index
      if (!result[columnIndex]) result[columnIndex] = []; // Initialize column if not present
      result[columnIndex].push(category); // Add category to the column
      return result;
    },
    []
  );

  const [submenuOpen, setSubmenuOpen] = useState(false);

  // Toggle submenu visibility on mobile
  const handleClick = () => {
    setSubmenuOpen(!submenuOpen);
  };

  // Open submenu on desktop (mouse hover)
  const handleMouseEnter = () => {
    if (window.innerWidth >= 768) {
      setSubmenuOpen(true);
    }
  };

  // Close submenu on desktop
  const handleMouseLeave = () => {
    if (window.innerWidth >= 768) {
      setSubmenuOpen(false);
    }
  };

  

  return (
    <div>
      {/* Shop button for desktop */}
      <Link
        to="/products"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="hidden md:flex justify-between items-center w-full px-4 py-2 text-sm hover:text-base-background-3"
      >
        <span className="text-base font-medium  hover:text-base-background-3">
          {ShopMenu}
        </span>
        <span
          className={`ml-2 transition-transform hover:text-base-background-3 duration-300 ${
            submenuOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <ArrowDown />
        </span>
      </Link>

      {/* Shop button for mobile */}
      <button
        onClick={handleClick}
        className="flex md:hidden justify-between items-center w-full px-4 py-2 text-sm hover:text-base-background-3"
      >
        <span className="text-base font-medium hover:text-base-background-3">
          {ShopMenu}
        </span>
        <span
          className={`ml-2 transition-transform hover:text-base-background-3 duration-300 ${
            submenuOpen ? "rotate-180" : "rotate-0"
          }`}
        >
          <ArrowDown />
        </span>
      </button>

      {/* Submenu for desktop */}
      <div
        className={`absolute hidden md:block z-50 bg-white shadow-md left-0 w-64 border border-gray-200 overflow-hidden transition-all duration-300 ${
          submenuOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
        style={{ height: submenuOpen ? "auto" : "0" }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="grid grid-cols-1 gap-2">
          {splitCategories.map((column, colIndex) => (
            <ul key={colIndex} className="space-y-2">
              {column.map((category) => (
                <li  onClick={() => {
                    
 
                }} key={category.id} className="relative group">
                  <Link
  to={`/categories/${category.id}`}
 
  className="block px-4 py-2 duration-300 hover:translate-x-2 text-base-accent-1 text-sm hover:text-base-background-3"
>
  {t(`category:categoryName-${category.id}`)}
</Link>

                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>

      {/* Submenu for mobile */}
      {submenuOpen && (
        <ul className="pl-4 mt-2 block md:hidden border-l border-gray-300 overflow-hidden transition-all duration-300 max-h-96 opacity-100">
          {categories?.map((category) => (
            <li key={category.id}>
              <Link
                to={`/categories/${category.id}`}
                onClick={()=>setMenuOpen(false)
                } // Close submenu on link click
                className="block px-4 py-2 text-sm hover:text-base-background-3"
              >
                {t(`category:categoryName-${category.id}`)}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Submenu;
