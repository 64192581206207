import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import profile from '../../assets/images/defaultProfile.jpg'
import Login from '../../assets/icons/Login';
import { logout } from '../../store/slices/authSlice';
import Person from '../../assets/icons/Person';
import Exit from '../../assets/icons/Exit';
import { useTranslation } from 'react-i18next';


const ProfileButton = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false); // State for profile dropdown
  
  const dispatch=useDispatch();
  
  const { i18n } = useTranslation();
    const {AccountDetails,logOut}=i18n.getDataByLanguage(i18n.language); 

  const user = useSelector((state) => state.auth.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const navigate = useNavigate();
  const handleProfileClick = () => { 
      setProfileMenuOpen((prev) => !prev);
  };
 
  const handleAccountClick=()=>{
    setProfileMenuOpen(false)
    if (user) {
// Check user role and redirect to the relevant panel

    if (user.roles[0].roleName === 'ROLE_ADMIN') {
      navigate('/admin/dashboard');
    } else if (user.roles[0].roleName === 'ROLE_USER') {
      
      navigate('/user/dashboard');
    }
  } else {
// If the user is not logged in, redirect to the login page
    navigate('/account/login');
  }
  }
  const logOutHandler=()=>{
    setProfileMenuOpen(false)
    dispatch(logout());

  }
  return (
    <>{ isAuth ? (
      <div className="relative">
      <img src={profile} onClick={handleProfileClick}  className=" rounded-[50%] cursor-pointer w-[40px] h-[40px] text-center text-white " alt="profile"  />
      {profileMenuOpen && (
          <div className="absolute z-50 -right-12 mt-2 w-40 text-base-accent-1 bg-white border shadow-md">
            <ul>
              <li>
                <button
                onClick={handleAccountClick}
                 
                  className="flex gap-2 items-center w-full py-2 px-2 text-left text-[14px] hover:bg-gray-200"
                >
                 <span><Person /></span> <span>{AccountDetails}</span>
                </button>
              </li>
              <li>
                <button
                  onClick={logOutHandler}
                  className="flex gap-2 items-center w-full text-left  py-2 px-2 text-[14px] hover:bg-gray-200"
                >
                  <span><Exit /></span> <span>{logOut}


                  </span>
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    ) : (
      <span>
        <Link to="/account/login">
          <Login />
        </Link>
      </span>
    )}
    </>

  );
};

export default ProfileButton;