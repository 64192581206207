import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import getToken from '../../utils/getToken';
import formatPrice from '../../utils/formatPrice';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { api } from "../../services/apiServices";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10);
  const [pageInput, setPageInput] = useState(""); // New state for user input page

  const navigate = useNavigate();
  const token = getToken();

  useEffect(() => {
    setLoading(true);
    const fetchOrders = async () => {
      try {
        const response = await api.get(
          `orders?pageNo=${currentPage}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOrders(response.data.content);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        setError('Failed to fetch orders');
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [currentPage, token, pageSize]);

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handlePageInputChange = (e) => {
    const value = e.target.value;
    setPageInput(value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber - 1); // Page input is 1-based, so we subtract 1 to set the correct index
    } else {
      alert('Invalid page number');
    }
    setPageInput(""); // Clear input after navigating
  };

  if (loading) return <Loader />;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="w-11/12 m-auto">
      <h5 className="text-xl md:text-2xl text-base-accent-1 my-8 font-semibold text-center md:text-left mb-4">
        Orders
      </h5>

      {/* Table Section */}
      <div className="overflow-x-scroll">
        <table className="min-w-full text-[14px] md:text-[16px] bg-white border border-gray-200 rounded-md">
          <thead className="text-base-accent-1">
            <tr>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Order ID</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Date</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">First Order</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Total Price</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Status</th>
              <th className="border-b px-4 py-2 text-left text-[14px] md:text-[16px]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order.id}>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => navigate(`/orders/${order.id}`)}
                  >
                    {order.id}
                  </button>
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  {moment(order.createdAt).format('YYYY-MM-DD HH:mm')}
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  {order.orderItems.length > 0 && (
                    <img
                      src={order.orderItems[0].imageUrl}
                      alt="First Product"
                      className="w-16 h-16 object-cover rounded-md"
                    />
                  )}
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  {formatPrice(order.totalPrice)}
                </td>
                <td className={`border-b px-4 py-2 text-[12px] md:text-[14px] ${order.status === 'COMPLETED' ? 'text-green-500' : 'text-yellow-400'} ${order.status === 'FAILED' && 'text-red-400'}`}>
                  {order.status}
                </td>
                <td className="border-b px-4 py-2 text-[14px] md:text-[16px]">
                  <button
                    className="text-[14px] text-base-accent-1 rounded-[30px] mr-2"
                    onClick={() => navigate(`/orders/${order.id}`)}
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center my-6 text-base md:text-lg">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
          className="px-4 py-2 bg-gray-200 rounded-lg"
        >
          Previous
        </button>

        {/* Page input for direct navigation */}
        <div className="flex gap-3 items-center">
          <label htmlFor="pageInput" className="mr-2">Go to Page:</label>
          <input
            type="number"
            id="pageInput"
            value={pageInput}
            onChange={handlePageInputChange}
            className="border p-2 rounded-[30px] text-[14px] w-28"
            min="1"
            max={totalPages}
          />
          <button
            onClick={handleGoToPage}
            className="bg-base-accent-1 text-white px-4 py-2 rounded-[30px] text-[14px]"
          >
            Go
          </button>
        </div>

        <span>
          Page {currentPage + 1} of {totalPages}
        </span>

        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages - 1}
          className="px-4 py-2 bg-gray-200 rounded-lg"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default OrdersPage;
