import React from 'react';
import skillicon from '../../assets/images/narart.png';



function SkillBox({ title, description, image }) {
    return (
        <div className='text-center flex flex-col cursor-grabbing gap-3 text-base-accent-1'>
            <img className='mx-auto w-[150px]'  src={skillicon} alt='icon' />
            <h6 className='text-[25px]'>{title}</h6>
            <p className='md:w-10/12 m-auto'>{description}</p>
        </div>
    );
}

export default SkillBox;