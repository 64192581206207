import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../../services/apiServices';
import getToken from '../../utils/getToken';
import FailedPayment from '../../assets/images/failedPayment.svg';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';


export function PaymentFailed() {
  const orderId = localStorage.getItem('orderId'); 
  const [isLoadingFetch, setIsLoadingFetch] = useState(true);
  
  const {i18n}=useTranslation()
    const {  PaymentFailed,
      proccessFailed,
      goToHomePayment,
      }=i18n.getDataByLanguage(i18n.language);
      
  useEffect(() => {
    const token = getToken();
    setIsLoadingFetch(true);

    const faildOrder = async () => {
      if (!orderId) {
        return;
      }

      try {
        await api.put(
          `orders/${orderId}/status?status=CANCELLED`,
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsLoadingFetch(false);

        localStorage.removeItem('orderId');
      } catch (error) {
        setIsLoadingFetch(false);

      }
    };

    faildOrder();
  }, [orderId]);

  return (
  <> {!isLoadingFetch?<Loader />: <div className=" py-16 flex flex-col items-center justify-center bg-red-50 text-red-600">
      <div className="w-11/12 m-auto h-[400px]  bg-white items-center md:w-1/2 flex flex-col md:flex-row justify-center  p-5 md:p-10 md:border md:border-1 rounded-[1rem]">
      <div className="w-full flex justify-center items-center flex-col">
       <div className='bg-red-300/50 flex items-center justify-center rounded-[50%] w-20 h-20'>
       <svg class="h-12 w-12  text-red-600 dark:text-green-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" ariaHidden="true" dataSlot="icon">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
        </svg> 
       </div>
          <h6 className="text-xl font-semibold text-red-600 my-4">{PaymentFailed}</h6>
          <p className="text-red-700 mb-6">
{proccessFailed}          </p>
          <div className="flex flex-col md:flex-row gap-4">
            
             
            <Link
              to="/"
              className="px-3 py-3 w-full md:w-fit text-center bg-red-100 text-red-800 rounded-[30px] hover:bg-red-200 transition duration-300"
            >
            {goToHomePayment}
            </Link>
          </div>
        </div>
        
      </div>
    </div>
   }
   </>
  );
}
